<template>
  <div class="content">
    <div>
      <p>일번권, 추가의상권 구매 시 10% 추가 지급!</p>
    </div>
    <p>【대상 상품】</p>
    <p>&lt; 일번권 ></p>
    <img src="/static/img/news/page13/vouchers.png" alt="" />
    <p>마작일번가의 고급 화폐로 작사 모집이나 상품 구입에 사용됩니다.</p>
    <p>
      황금으로 만들었다는 소문이 있어, 밤에도 반짝 반짝 그 위태를 자랑합니다.
    </p>
     <br />
     
    <p>&lt; 추가의상권 ></p>
    <img src="/static/img/news/page13/outfit_tokens.png" alt="" />

    <p>작사들의 의상을 구매하는대 사용됩니다.</p>
     <br />
    <p>&lt; 이벤트 참여 방법 ></p>
    <p>이벤트 기간 동안 위 2개의 상품을 STOVE 마작일번가에서 구매</p>
     <br />
    <p>【지급 보상】</p>
    <p>아이템를 10% 더 드려요!</p>
    <p>위 2개의 상품을 구매하신 아이템 개수의 10%를 추가로 더 드립니다!</p>
     <br />
    <p>【유의 사항】</p>
    <p>1.아이템 추가 보상은 우편함을 통해 지급될 예정입니다.</p>
    <p>2.초회 구매 보상은 10% 추가 지급에 포함되지 않습니다.</p>
    <p>
      (초회 구매 보상을 제외한 일번권, 추가의상권의 구매 개수의 10%가 추가
      지급됩니다.)
    </p>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    imgSrc() {
      return `/static/img/news/page11/content_${this.$store.state.language}.jpg`;
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  margin: 30px 20px;
  text-align: left;
  p {
    margin: 20px 0;
    color: #ffffff;
    font-weight: 500;
    white-space: pre-line;
  }
  img {
    display: block;
  }
}
</style>
