<template>
  <div class="content">
    <div>
      <p>{{ $t("news.p44") }}</p>
    </div>
    <div>
      <img :src="imgSrc" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed:{
    imgSrc(){
      return `/static/img/news/page11/content_${this.$store.state.language}.jpg`
    }
  }
};
</script>
<style lang="scss" scoped>
.content {
  margin: 30px 20px;
  text-align: left;
  p {
    margin: 20px 0;
    color: #ffffff;
    font-weight: 500;
    white-space: pre-line;
  }
  img {
    width: 100%;
    // max-width: 768px;
    height: auto;
  }
}
</style>
