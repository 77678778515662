<template>
  <div class="content">
    <div>
      <p>ゲームアプリ究極攻略『アルテマ』にて、『麻雀一番街』のプレイレビューが掲載されました！</p>
      <p>ありがとうございます！</p>
    </div>
    <div>
      <p class="titleName">▼『アルテマ』様の記事はこちら</p>
      <p>『麻雀一番街』プレイレビュー！いつでもどこでも遊べる本格型麻雀ゲーム</p>
      <p><a class="ytLink" href="https://altema.jp/marjanichibangai">https://altema.jp/marjanichibangai</a></p>
    </div>
    <div>
      <p class="titleName">▼「麻雀一番街」ダウンロードはこちらから</p>
      <p>・iOS版： <a class="ytLink" href="https://apps.apple.com/app/id1578816591">https://apps.apple.com/app/id1578816591</a></p>
      <p>・Android版： <a class="ytLink" href="https://play.google.com/store/apps/details?id=com.riichicity.happywoods">https://play.google.com/store/apps/details?id=com.riichicity.happywoods</a></p>
      <p>・PC版： <a class="ytLink" href="https://d3qgi0t347dz44.cloudfront.net/release/windowspack/Mahjong-jp-1-1-2.zip">https://d3qgi0t347dz44.cloudfront.net/release/windowspack/Mahjong-jp-1-1-2.zip</a></p> 
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.content{
  margin: 10px 20px 0 20px; 
  text-align: left;
  p{
    color: #ffffff;
    text-indent: 1em; 
    font-weight: 500;
    &.titleName{
      // font-family: 'Source Han Sans-bold';
      font-size: 32px;
      text-indent: 0em;
    }
    &.subtitleName{
      font-size: 26px;
      text-indent: 0em;
    }
    &.link{
      word-break: keep-all;
    }
  }
  img{
    width: 100%;
    max-width: 300px;
    height: auto;
  }
}
@media only screen and (max-width: 767px) {
    a{
    &.ytLink{
      display: inline-block;
      word-break: keep-all;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
