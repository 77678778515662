export const allPageList = (locale) => {
  const newsPageList = [
    {
      href: "/news/page11",
      image: `/static/img/news/page11/header_${locale}.jpg`,
      title: "news.p43",
      subtitle: "2022.12.05",
      button: "MORE",
      topTag: "news.p6",
    },
    {
      href: "/news/page10",
      image: `/static/img/news/page10/header_${locale}.jpg`,
      title: "news.p27",
      subtitle: "2022.09.30",
      button: "MORE",
      topTag: "news.p3",
    },
    {
      href: "/news/page09",
      // image: `/static/img/news/headerImagePage09.jpg`,
      image: `/static/img/news/page09/header_${locale}.jpg`,
      title: "news.p20",
      subtitle: "2022.09.23",
      button: "MORE",
      topTag: "news.p6",
    },
    {
      href: "/news/page08",
      image: `/static/img/news/headerImagePage08-1.png`,
      title: "news.p7",
      subtitle: "2022.06.30",
      button: "MORE",
      topTag: "news.p6",
    },
    {
      href: "/news/page07",
      image: "/static/img/news/headerImagePage07.png",
      title: "【イベント】一番街桜祭り、現在開催中！",
      subtitle: "2022.03.22",
      button: "MORE",
      topTag: "news.p6",
    },
    {
      href: "/news/page06",
      image: "/static/img/news/headerImagePage06.jpg",
      title: "開発者からのご挨拶",
      subtitle: "2022.01.25",
      button: "MORE",
      topTag: "news.p3",
    },
    {
      href: "/news/page05",
      image: "/static/img/news/headerImagePage05.jpeg",
      title: "『アルテマ』にて、『麻雀一番街』のプレイレビューが掲載！",
      subtitle: "2022.01.21",
      button: "MORE",
      topTag: "news.p3",
    },
    {
      href: "/news/page04",
      image: "/static/img/news/headerImagePage04.jpeg",
      title: "『麻雀一番街』配信開始！！",
      subtitle: "2022.01.19",
      button: "MORE",
      topTag: "news.p3",
    },
    {
      href: "/news/page03",
      image: "/static/img/news/headerImagePage03.png",
      title: "瑞原明奈、『麻雀一番街』公式アンバサダーに就任！！",
      subtitle: "2021.12.25",
      button: "MORE",
      topTag: "news.p3",
    },
    // {
    //   href: '/news/page02',
    //   image: '/static/img/news/headerImagePage02.jpg',
    //   title: '松本吉弘、『麻雀一番街』公式アンバサダーに就任！！',
    //   subtitle: '2021.12.13',
    //   button:'MORE',
    //   topTag: 'news.p3'
    // },
    {
      href: "/news/page01",
      image: "/static/img/news/headerImagePage01.jpg",
      title: "『麻雀一番街』の事前登録受付がスタート！！",
      subtitle: "2021.11.30",
      button: "MORE",
      topTag: "news.p3",
    },
  ];
  if (locale === "kr") {
    newsPageList.unshift(
      {
        href: "/news/page13",
        image: `/static/img/news/page13/header.png`,
        title: "[아이템 추가지급 이벤트]",
        subtitle: "2024.05.31",
        button: "MORE",
        topTag: "news.p6",
      },
      {
        href: "/news/page12",
        image: `/static/img/news/page12/list_img.png`,
        title: "[STOVE 10% 캐시백 이벤트]",
        subtitle: "2024.05.31",
        button: "MORE",
        topTag: "news.p6",
      }
    );
  }

  return newsPageList;
};
export const newsPageList = (locale) => {
  return allPageList(locale).filter((item) => item.topTag === "news.p3");
};
export const MaintenanceList = [];
export const updatedList = [];
export const eventPageList = (locale) => {
  return allPageList(locale).filter((item) => item.topTag === "news.p6");
};

export const downloadInfo = {
  jp: [
    {
      title: "・公式サイト:",
      link: "https://www.mahjong-jp.com",
    },
    {
      title: "・Twitter:",
      link: "https://twitter.com/RiichiCity_JP",
    },
    {
      title: "・Discord:",
      link: "https://discord.com/invite/5z3Nw2rPNx",
    },
  ],
  en: [
    {
      title: "・Official website:",
      link: "https://www.mahjong-jp.com",
    },
    {
      title: "・Facebook:",
      link: "https://www.facebook.com/Riichi-City-107331518266065",
    },
    {
      title: "・Discord:",
      link: "https://discord.com/invite/5z3Nw2rPNx",
    },
  ],
  kr: [
    {
      title: "・공식 사이트:",
      link: "https://www.mahjong-jp.com",
    },
    {
      title: "・Twitter:",
      link: "https://twitter.com/RiichiCity_KR",
    },
    {
      title: "・Discord:",
      link: "https://discord.com/invite/5z3Nw2rPNx",
    },
  ],
  tc: [
    {
      title: "・官網：",
      link: "https://www.mahjong-jp.com",
    },
    {
      title: "・臉書：",
      link: "https://www.facebook.com/RiichiCity/",
    },
    {
      title: "・Line群組：",
      link: "http://www.mahjong-jp.com/zh/linegroup",
    },
    {
      title: "・Discord群組：",
      link: "https://discord.com/invite/5z3Nw2rPNx",
    },
  ],
};
