<template>
  <div class="content">
    <div>
      <p>一番街桜祭りが始まりました！</p>
      <p>汐月ちゃんと一緒にお花見に行きましょう！</p>
      <p>▼新しい着せ替え：バニーガール、お菓子ガール</p>
      <div class="div_img">
        <img class="dress" src="/static/img/news/page07/image02.png">
        <img class="dress" src="/static/img/news/page07/image03.png">
      </div>
      
      <p>▼イベント報酬：ガチャ券、ロビー演出・桜、雀卓背景・お花見など</p>
      <div class="div_img">
        <img class="coupon" src="/static/img/news/page07/image04.png">
        <img class="coupon" src="/static/img/news/page07/image05.png">
        <img class="coupon" src="/static/img/news/page07/image06.png">
      </div>
  
      <img class="award" src="/static/img/news/page07/image07.png">
      <p>サクラをたくさん集めて、報酬をゲットしましょう！</p>
    </div>
    <div>
      <p class="titleName">▼「麻雀一番街」ダウンロードはこちらから</p>
      <p>・iOS版： <a class="ytLink" href="https://apps.apple.com/app/id1578816591">https://apps.apple.com/app/id1578816591</a></p>
      <p>・Android版： <a class="ytLink" href="https://play.google.com/store/apps/details?id=com.riichicity.happywoods">https://play.google.com/store/apps/details?id=com.riichicity.happywoods</a></p>
      <p>・PC版： <a class="ytLink" href="https://d3qgi0t347dz44.cloudfront.net/release/windowspack/Mahjong-jp-1-1-2.zip">https://d3qgi0t347dz44.cloudfront.net/release/windowspack/Mahjong-jp-1-1-2.zip</a></p> 
      <p class="titleName">▼お問い合わせはこちら</p>
      <p>riichicitysupport@mahjong-jp.com</p>
      <p>皆様がゲームを心よりお楽しみいただけるように努めてまいります。</p>
      <p>今後とも『麻雀一番街』をよろしくお願いします！</p>
      <p>「麻雀一番街」委員会</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.content{
  margin: 10px 20px 0 20px; 
  text-align: left;
  p{
    color: #ffffff;
    text-indent: 1em; 
    font-weight: 500;
    &.titleName{
      // font-family: 'Source Han Sans-bold';
      font-size: 32px;
      text-indent: 0em;
    }
    &.subtitleName{
      font-size: 26px;
      text-indent: 0em;
    }
    &.link{
      word-break: keep-all;
    }
  }
  .div_img {
    margin: 0px -10px;
  }
  .dress {
    display: inline-block;
    padding: 10px;
    width: 100%;
    max-width: 400px;
    height: auto;
  }
  .coupon {
    display: inline-block;
    padding: 10px;
    width: 100%;
    max-width: 300px;
    height: auto;
    vertical-align: top;
  }
  .award {
    margin-top: 30px;
    display: block;
    width: 100%;
    height: auto;
  }
}
@media only screen and (max-width: 767px) {
    a{
    &.ytLink{
      display: inline-block;
      word-break: keep-all;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
