<template>
  <div class="content">
    <div>
      <p>「麻雀一番街」をご利用くださっている皆様、はじめまして。この度「麻雀一番街」をリリースさせていただきましたLancelot Tech Ltd.代表のFrank（フランク）です。本名はGong Chok Yee（ゴンチョイェ）です。</p>
      <p>「麻雀一番街」をご利用いただき、誠にありがとうございます。この度、期待して頂いた皆様に良いゲーム体験をご提供できず、大変申し訳ありません。修正中にも関わらず、応援してくださっている皆様、大変感謝しております</p>
      <p>また、弊社の開発チームメンバーは、自分のノートにてご紹介させていただきます。（本人希望により掲載）</p>
      <p>ご覧いただけましたら幸いです。</p>
    </div>
    <div>
      <p class="titleName">▼Noteはこちら</p>
      <p><a class="ytLink" href="https://note.com/frankmahjong/n/n44f8c60d3aa4">https://note.com/frankmahjong/n/n44f8c60d3aa4</a></p>
      <p>今後より一層のサービス向上を目指して、社員一同全力を持って取り組んで参ります。</p>
      <p>今後とも『麻雀一番街』をどうぞよろしくお願いいたします！</p>
      <p>Frank（フランク）＠麻雀一番街</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.content{
  margin: 10px 20px 0 20px; 
  text-align: left;
  p{
    color: #ffffff;
    text-indent: 1em; 
    font-weight: 500;
    &.titleName{
      // font-family: 'Source Han Sans-bold';
      font-size: 32px;
      text-indent: 0em;
    }
    &.subtitleName{
      font-size: 26px;
      text-indent: 0em;
    }
    &.link{
      word-break: keep-all;
    }
  }
  img{
    width: 100%;
    max-width: 300px;
    height: auto;
  }
}
@media only screen and (max-width: 767px) {
    a{
    &.ytLink{
      display: inline-block;
      word-break: keep-all;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
