<template>
  <div class="content">
    <p>【Event 안내】</p>
    <p style="text-decoration: underline">
      기간: 2024년 5월 31일 론칭 후부터 6월 13일(목) 23시 59분까지
    </p>
    <p>
      이벤트 기간 동안 마작일번가에서 구매하신 금액의 10%를 캐시백으로 드려요!  
    </p>
     <br />
    <p>【참여 방법】  </p>
    <p>이벤트 기간 동안 STOVE 마작일번가에서 구매하신 전체 금액의  </p>
    <p>10%를! 캐시백(스토브 캐시)로 돌려드려요!  </p>
     <br />
    <p>  【유의 사항】  </p>
    <p>1.캐시백 이벤트는 STOVE 마작일번가에서 결제하신 대상만 적용됩니다.  </p>
    <p>2.캐시백 보상은 이벤트 종료 후 영업일 10일 내 지급될 예정입니다.  </p>
    <p>3.캐시백 보상(스토브 캐시)의 유효 기간은 지급일로부터 7일입니다.  </p>
    <p>
      4..스토브 캐시는 보너스 캐시로 충전/지급 순서와 상관없이 구매한 캐시가
      모두 소진된 5. 보너스 캐시가 소진됩니다.  
    </p>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    imgSrc() {
      return `/static/img/news/page11/content_${this.$store.state.language}.jpg`;
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  margin: 30px 20px;
  text-align: left;
  p {
    margin: 20px 0;
    color: #ffffff;
    font-weight: 500;
    white-space: pre-line;
  }
  img {
    width: 100%;
    // max-width: 768px;
    height: auto;
  }
}
</style>
