<template>
  <div class="content">
    <div>
      <p>2022年1月19日（水）より、都市風対戦型オンライン麻雀ゲーム『麻雀一番街』が配信開始いたしました！</p>
      <p>キャプテンの皆様、ご入居おめでとうございます！</p>
      <p>一番街で沢山の麻雀を打って、真剣勝負の競技麻雀を楽しもう！</p>
      <p>正念場（オーラス）を乗り切って、一番（トップ）になれ！</p>
    </div>
    <div>
      <p class="titleName">◆ダウンロードはこちらから</p>
      <p>・iOS版： <a class="ytLink" href="https://apps.apple.com/app/id1578816591">https://apps.apple.com/app/id1578816591</a></p>
      <p>・Android版： <a class="ytLink" href="https://play.google.com/store/apps/details?id=com.riichicity.happywoods">https://play.google.com/store/apps/details?id=com.riichicity.happywoods</a></p>
      <p>・PC版： <a class="ytLink" href="https://d3qgi0t347dz44.cloudfront.net/release/windowspack/Mahjong-jp-1-1-2.zip">https://d3qgi0t347dz44.cloudfront.net/release/windowspack/Mahjong-jp-1-1-2.zip</a></p> 
    </div>
    <div>
      <p class="titleName">◆ゲームに関する最新情報は公式Twitter、公式サイトで更新しております。</p>
      <p>・Twitter: <a class="ytLink" href="https://twitter.com/RiichiCity_JP"> https://twitter.com/RiichiCity_JP</a></p>
      <p>・公式サイト：<a class="ytLink" href="https://www.mahjong-jp.com"> https://www.mahjong-jp.com</a></p>
    </div>
    <div>
      <p class="titleName">◆お問い合わせはこちら</p>
      <p><a class="ytLink" href="riichicitysupport@mahjong-jp.com">riichicitysupport@mahjong-jp.com</a></p>
      <p>皆様がゲームを心よりお楽しみいただけるように努めてまいります。</p>
      <p>今後とも『麻雀一番街』をよろしくお願いします！</p>
      <p>「麻雀一番街」委員会</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.content{
  margin: 10px 20px 0 20px; 
  text-align: left;
  p{
    color: #ffffff;
    text-indent: 1em; 
    font-weight: 500;
    &.titleName{
      // font-family: 'Source Han Sans-bold';
      font-size: 32px;
      text-indent: 0em;
    }
    &.subtitleName{
      font-size: 26px;
      text-indent: 0em;
    }
    &.link{
      word-break: keep-all;
    }
  }
  img{
    width: 100%;
    max-width: 300px;
    height: auto;
  }
}
@media only screen and (max-width: 767px) {
    a{
    &.ytLink{
      display: inline-block;
      word-break: keep-all;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
