import { render, staticRenderFns } from "./newsPage11.vue?vue&type=template&id=2b0de1bf&scoped=true&"
import script from "./newsPage11.vue?vue&type=script&lang=js&"
export * from "./newsPage11.vue?vue&type=script&lang=js&"
import style0 from "./newsPage11.vue?vue&type=style&index=0&id=2b0de1bf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b0de1bf",
  null
  
)

export default component.exports