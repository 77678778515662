<template>
  <div class="content">
    <div>
      <pre>{{ $t('news.p8')}}</pre>
      <pre>{{ $t('news.p9')}}</pre>
    </div>
    <div>
      <pre>{{ $t('news.p10')}}</pre>
      <pre>{{ $t('news.p11')}}</pre>
      <div v-for="(item, i) in newImage" :key="i">
        <img :src="item">
      </div>
      <p>{{ $t('news.p12')}}</p>
      <div v-for="(item, i) in swimmingSkin" :key="`skin_`+i">
        <img :src="item">
      </div>
    </div>
    <div>
      <pre>{{ $t('news.p13')}}</pre>
      <p>{{ $t('news.p14')}}</p>
      <div v-for="(item, i) in downloadLink" :key="`link_`+i">
        <i18n :path="item.tag" tag="p">
          <a
            class="text-decoration-none"
            place="link"
            :href="item.link"
          >
            {{ item.link }}
          </a>
        </i18n>
      </div>
      <p>{{ $t('news.p18')}}</p>
      <div v-for="(item, i) in downloadSection" :key="`section_`+i">
        <p>{{ item.title }}
          <a :href="`${item.link}`">{{ item.link }}</a>
        </p>
      </div>
      <p>{{ $t('news.p19')}}</p>
      <a
        class="text-decoration-none"
        place="link"
        href="mailto:riichicitysupport@mahjong-jp.com"
      >
        riichicitysupport@mahjong-jp.com
      </a>
    </div>
  </div>
</template>
<script>
import { downloadInfo } from '../../configs/news-content'
export default {
  data() {
    return {
      downloadLink: [
        {
          tag: 'news.p15',
          link: 'https://bit.ly/3xQ99RE'
        },
        {
          tag: 'news.p16',
          link: 'https://bit.ly/3HOlcUo'
        },
        {
          tag: 'news.p17',
          link: 'https://bit.ly/3uj1lan'
        }
      ],
    }
  },
  computed:{
    newImage(){
      return [
        `/static/img/news/page08/page08-1_${this.$i18n.locale}.jpg`,
        `/static/img/news/page08/page08-2_${this.$i18n.locale}.jpg`,
        `/static/img/news/page08/page08-3_${this.$i18n.locale}.jpg`,
        `/static/img/news/page08/page08-4_${this.$i18n.locale}.jpg`,
      ]
    },
    swimmingSkin(){
      return [
        `/static/img/news/page08/page08-5_${this.$i18n.locale}.jpg`,
        `/static/img/news/page08/page08-6_${this.$i18n.locale}.jpg`,
        `/static/img/news/page08/page08-7_${this.$i18n.locale}.jpg`,
        `/static/img/news/page08/page08-8_${this.$i18n.locale}.jpg`,
      ]
    },
    downloadSection(){
      return downloadInfo[this.$i18n.locale]
    }
  }
}
</script>
<style lang="scss" scoped>
.content{
  margin: 30px 20px;
  text-align: left;
  p, pre{
    margin-top: 20px;
    color: #ffffff;
    font-weight: 500;
    white-space: pre-line;
    &.titleName{
      font-weight: 500;
      font-size: 32px;
      text-indent: 0em;
    }
    &.subtitleName{
      font-size: 26px;
      text-indent: 0em;
    }
    a {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  img{
    width: 100%;
    max-width: 768px;
    height: auto;
  }
}
@media only screen and (max-width: 767px) {
    a{
    &.ytLink{
      display: inline-block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
@media only screen and (max-width: 600px) {
  a{
    display: block;
  }
}
</style>