<template>
  <div>
    <div class="contents">
      <div class="background"></div>
      <v-container>
        <div class="contentBackground">
          <div class="newsContent">
            <div class="newsImage">
              <img v-if="newsData.nkey" :src="serverContent.main_img" alt="" />
              <img v-else :src="newsSetting.image" />
            </div>
            <div class="newsIntro">
              <div class="top">
                <p v-if="newsData.nkey" class="titleName">
                  {{ serverContent.title }}
                </p>
                <p v-else class="titleName">
                  {{ translate(newsSetting.title) }}
                </p>
                <div class="innerMiddle">
                  <!-- mobile -->
                  <p class="hidden-md-and-up subtitleName">
                    {{ newsSetting.subtitle }}
                  </p>
                  <div class="hidden-md-and-up tagMobile">
                    <p>{{ translate(newsSetting.topTag) }}</p>
                  </div>
                  <div class="hidden-md-and-up headerLine"></div>
                  <!-- pc -->
                  <p class="hidden-sm-and-down subtitleName">
                    {{ newsSetting.subtitle }}
                  </p>
                  <div class="hidden-sm-and-down tag">
                    <p>{{ translate(newsSetting.topTag) }}</p>
                  </div>
                </div>
                <div class="hidden-sm-and-down headerLine"></div>
              </div>
              <div class="middle">
                <component
                  :is="newsSetting.middlePage"
                  :content="serverContent.content"
                />
              </div>
              <div v-if="newsSetting.button" class="bottom">
                <p>{{ newsSetting.button }}</p>
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </div>
  </div>
</template>
<script>
import newsDetail from "@/mixins/news/newsDetail";
import newsPage from "@/components/news/newsPage.vue";
import newsPage01 from "@/components/news/newsPage01.vue";
// import newsPage02 from "@/components/news/newsPage02.vue"
import newsPage03 from "@/components/news/newsPage03.vue";
import newsPage04 from "@/components/news/newsPage04.vue";
import newsPage05 from "@/components/news/newsPage05.vue";
import newsPage06 from "@/components/news/newsPage06.vue";
import newsPage07 from "@/components/news/newsPage07.vue";
import newsPage08 from "@/components/news/newsPage08.vue";
import newsPage09 from "@/components/news/newsPage09.vue";
import newsPage10 from "@/components/news/newsPage10.vue";
import newsPage11 from "@/components/news/newsPage11.vue";
import newsPage12 from "@/components/news/newsPage12.vue";
import newsPage13 from "@/components/news/newsPage13.vue";

export default {
  components: {
    newsPage,
    newsPage01,
    // newsPage02,
    newsPage03,
    newsPage04,
    newsPage05,
    newsPage06,
    newsPage07,
    newsPage08,
    newsPage09,
    newsPage10,
    newsPage11,
    newsPage12,
    newsPage13,
  },
  mixins: [newsDetail],
  data() {
    return {
      newsId: "",
    };
  },
  created() {
    this.newsId = this.$route.params.id;
  },
  computed: {
    news() {
      const page12 = {
        code: "page13",
        image: `/static/img/news/page13/header.png`,
        title: "[아이템 추가지급 이벤트]",
        subtitle: "2024.05.31",
        middlePage: "newsPage13",
        button: "",
        topTag: "news.p6",
      };

      const page13 = {
        code: "page12",
        image: `/static/img/news/page12/header.png`,
        title: "[STOVE 10% 캐시백 이벤트]",
        subtitle: "2024.05.31",
        middlePage: "newsPage12",
        button: "",
        topTag: "news.p6",
      };
      const newList = [
        {
          code: "page11",
          image: `/static/img/news/page11/header_${this.$i18n.locale}.jpg`,
          title: "news.p43",
          subtitle: "2022.12.05",
          middlePage: "newsPage11",
          button: "",
          topTag: "news.p6",
        },
        {
          code: "page10",
          image: `/static/img/news/page10/header_${this.$i18n.locale}.jpg`,
          title: "news.p27",
          subtitle: "2022.09.30",
          middlePage: "newsPage10",
          button: "",
          topTag: "news.p3",
        },
        {
          code: "page09",
          image: `/static/img/news/page09/header_${this.$i18n.locale}.jpg`,
          title: "news.p20",
          subtitle: "2022.09.23",
          middlePage: "newsPage09",
          button: "",
          topTag: "news.p6",
        },
        {
          code: "page08",
          image: `/static/img/news/headerImagePage08-1.png`,
          title: "news.p7",
          subtitle: "2022.06.30",
          middlePage: "newsPage08",
          button: "",
          topTag: "news.p6",
        },
        {
          code: "page07",
          image: "/static/img/news/headerImagePage07.png",
          title: "【イベント】一番街桜祭り、現在開催中！",
          subtitle: "2022.03.22",
          middlePage: "newsPage07",
          button: "",
          topTag: "イベント",
        },
        {
          code: "page06",
          image: "/static/img/news/headerImagePage06.jpg",
          title: "開発者からのご挨拶",
          subtitle: "2022.01.25",
          middlePage: "newsPage06",
          button: "",
          topTag: "お知らせ",
        },
        {
          code: "page05",
          image: "/static/img/news/headerImagePage05.jpeg",
          title: "『アルテマ』にて、『麻雀一番街』のプレイレビューが掲載！",
          subtitle: "2022.01.21",
          middlePage: "newsPage05",
          button: "",
          topTag: "お知らせ",
        },
        {
          code: "page04",
          image: "/static/img/news/headerImagePage04.jpeg",
          title: "『麻雀一番街』配信開始！！",
          subtitle: "2022.01.19",
          middlePage: "newsPage04",
          button: "",
          topTag: "お知らせ",
        },
        {
          code: "page03",
          image: "/static/img/news/headerImagePage03.png",
          title: "瑞原明奈、『麻雀一番街』公式アンバサダーに就任！！",
          subtitle: "2021.12.25",
          middlePage: "newsPage03",
          button: "",
          topTag: "お知らせ",
        },
        // {
        //   code: 'page02',
        //   image: '/static/img/news/headerImagePage02.jpg',
        //   title: '松本吉弘、『麻雀一番街』公式アンバサダーに就任！！',
        //   subtitle: '2021.12.13',
        //   middlePage: 'newsPage02',
        //   button:'',
        //   topTag: 'お知らせ'
        // },
        {
          code: "page01",
          image: "/static/img/news/headerImagePage01.jpg",
          title: "『麻雀一番街』の事前登録受付がスタート！！",
          subtitle: "2021.11.30",
          middlePage: "newsPage01",
          button: "",
          topTag: "お知らせ",
        },
      ];
      if (this.$i18n.locale === "kr") {
        newList.unshift(page12);
        newList.unshift(page13);
      }
      return newList;
    },
    newsSetting() {
      if (!this.newsId) {
        return null;
      }
      if (this.newsData.nkey) {
        return this.newsData;
      }
      return this.news.find((x) => x.code === this.newsId) || {};
    },
  },
};
</script>
<style lang="scss" scoped>
.background {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-image: url("/static/img/common/contentBackground.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0;
}

.header {
  position: relative;
  width: 100%;
  max-height: 500px;
  z-index: 3;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
.container {
  max-width: 1200px;
}
.contentBackground {
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
}
.newsContent {
  height: 100%;
  margin-top: 30px;
  background-color: #38357d;
  position: relative;
  display: flex;
  flex-direction: column;
  .newsImage {
    width: 100%;
    img {
      padding: 20px;
      width: 100%;
      height: auto;
    }
  }
  .newsIntro {
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 60px;
    .top {
      .titleName {
        color: #ffffff;
        font-size: 40px;
        margin-bottom: 0;
      }
      .headerLine {
        width: 100%;
        height: 0.1rem;
        background-color: #7d8dfd;
      }
      .innerMiddle {
        margin-bottom: 50px;
        .subtitleName {
          float: right;
          font-weight: 500;
          text-align: right;
          font-size: 30px;
          margin: 0;
          color: #7d8dfd;
        }
        .tag {
          float: left;
          text-align: center;
          width: 177px;
          height: 44px;
          background-image: url("/static/img/news/tagImage.png");
          background-repeat: no-repeat;
          background-size: cover;
          p {
            color: #ffffff;
            position: relative;
            margin: 10px auto;
          }
        }
      }
    }
    .bottom {
      position: relative;
      text-align: center;
      margin: 0 auto;
      background-image: url("/static/img/home/bottom/roleButton.png");
      background-repeat: no-repeat;
      background-size: contain;
      height: 70px;
      width: 220px;
      p {
        position: relative;
        margin: 0 auto;
        top: 7px;
        font-size: 30px;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .flexBox {
    justify-content: center;
    flex-wrap: wrap;
  }
  .inline-display {
    .item {
      width: 180px;
    }
  }
  .newsContent {
    .newsIntro {
      .top {
        .titleName {
          font-size: 30px;
        }
        .subtitleName {
          font-size: 20px;
        }
      }
    }
  }
}
@media only screen and (max-width: 980px) {
  .contentBackground {
    // width: 450px;
    margin: 0 auto;
    .row {
      flex-direction: column;
      flex-wrap: nowrap;
    }
    .newsContent {
      margin-left: 20px;
      margin-right: 20px;
      .newsImage {
        img {
          margin: 0;
        }
      }
      .newsIntro {
        margin-top: 0;
        max-width: 90%;
        .top {
          .titleName {
            font-size: 25px;
          }
          .innerMiddle {
            .subtitleName {
              float: left;
            }
            .tagMobile {
              position: absolute;
              text-align: center;
              width: 177px;
              height: 44px;
              top: -20px;
              right: -10px;
              background-image: url("/static/img/news/tagImage.png");
              background-repeat: no-repeat;
              background-size: cover;
              p {
                color: #ffffff;
                position: relative;
                margin: 10px auto;
              }
            }
          }
        }
        .bottom {
          p {
            font-size: 25px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .contentBackground {
    .newsContent {
      .newsImage {
        flex: 0;
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .inline-display {
    .item {
      width: 150px;
      font-size: 20px;
    }
  }
  .contentBackground {
    .newsContent {
      .newsIntro {
        .top {
          .innerMiddle {
            .tagMobile {
              top: -10px;
              width: 127px;
              height: 33px;
              p {
                margin: 5px auto;
              }
            }
          }
        }
      }
      .newsImage {
        flex: 0;
      }
    }
  }
}
</style>
