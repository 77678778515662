<template>
  <div class="content">
    <div>
      <pre>{{ $t('news.p21')}}</pre>
      <pre>{{ $t('news.p22')}}</pre>
    </div>
    <div>
      <img :src="`/static/img/news/page09/page09-1_${this.$i18n.locale}.jpg`" alt="">
    </div>
    <div>
      <pre>{{ $t('news.p23')}}</pre>
      <div v-for="(item,i) in newImage" :key="i">
        <img :src=item alt="">
      </div>
    </div>
    <div>
      <pre>{{ $t('news.p24')}}</pre>
      <img :src="`/static/img/news/page09/page09-6_${this.$i18n.locale}.jpg`" alt="">
    </div>
    <div>
      <pre>{{ $t('news.p25')}}</pre>
      <img :src="`/static/img/news/page09/page09-7_${this.$i18n.locale}.jpg`" alt="">
    </div>
    <div>
      <pre>{{ $t('news.p14')}}</pre>
      <div v-for="(item, i) in downloadLink" :key="`link_`+i">
        <i18n :path="item.tag" tag="p">
          <a 
            place="link"
            :href="item.link"
          >
            {{ item.link }}
          </a>
        </i18n>
      </div>
      <p>{{ $t('news.p18')}}</p>
      <div v-for="(item, i) in downloadSection" :key="`section_`+i">
        <p>{{ item.title }}
          <a :href="`${item.link}`">{{ item.link }}</a>
        </p>
      </div>
      <p>{{ $t('news.p19')}}</p>
      <p>
        <a
        place="link"
        href="mailto:riichicitysupport@mahjong-jp.com"
      >
        riichicitysupport@mahjong-jp.com
      </a>
      </p>
    </div>
  </div>
</template>
<script>
import { downloadInfo } from '../../configs/news-content'
export default {
  data() {
    return {
      downloadLink: [
        {
          tag: 'news.p15',
          link: 'https://apps.apple.com/app/id1578816591'
        },
        {
          tag: 'news.p16',
          link: 'https://play.google.com/store/apps/details?id=com.riichicity.happywoods'
        },
        {
          tag: 'news.p17',
          link: 'https://d3qgi0t347dz44.cloudfront.net/release/windowspack/Windows_1.1.4.zip'
        },
        {
          tag: 'news.p26',
          link: 'https://store.steampowered.com/app/1954420/_/'
        },
      ],
    }
  },
  computed:{
    newImage(){
      return [
        `/static/img/news/page09/page09-2_${this.$i18n.locale}.jpg`,
        `/static/img/news/page09/page09-3_${this.$i18n.locale}.jpg`,
        `/static/img/news/page09/page09-4_${this.$i18n.locale}.jpg`,
        `/static/img/news/page09/page09-5_${this.$i18n.locale}.jpg`,
      ]
    },
    downloadSection(){
      return downloadInfo[this.$i18n.locale]
    }
  }
}
</script>
<style lang="scss" scoped>
.content{
  margin: 30px 20px;
  text-align: left;
  p, pre{
    margin: 20px 0;
    color: #ffffff;
    font-weight: 500;
    white-space: pre-line;
    a {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-decoration: none;
    }
  }
  img{
    width: 100%;
    max-width: 768px;
    height: auto;
  }
}
@media only screen and (max-width: 767px) {
    a{
    &.ytLink{
      display: inline-block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
@media only screen and (max-width: 600px) {
  a{
    display: block;
  }
}
</style>