<template>
  <div class="content">
    <div>
      <p>この度、U-NEXT Pirates所属・現役Mリーガーとして大活躍中の瑞原明奈プロが、『麻雀一番街』公式アンバサダーに就任したことをお知らせいたします。</p>
      <p>今後、アプリの情報発信、イベントやキャンペーンの参加を通じて、麻雀の魅力を一般の皆様へ広める役割を担います。</p>
      <p>今後のコラボレーションにぜひご期待ください！</p>
    </div>
    <div>
      <p class="titleName">◆『麻雀一番街』公式アンバサダー瑞原明奈プロについて</p>
      <img src="/static/img/news/page03/image01.jpg">
      <p>瑞原明奈（みずはら　あきな）</p>
      <p>生年月日：1986年11月19日</p>
      <p>出身地：長崎県佐世保市</p> 
      <p>職業：プロ雀士</p>
      <p>所属：最高位戦日本プロ麻雀協会、MリーグU-NEXT Pirates</p>
      <p>獲得タイトル：</p>
      <p>麻雀ウォッチ プリンセスリーグ2019優勝</p>
    </div>
    <div>
      <p class="titleName">■「麻雀一番街」概要</p>
      <p>タイトル名 ：麻雀一番街</p>
      <img src="/static/img/news/page02Image02.png">
      <p>配信予定日 ：2022年1月</p> 
      <p>プレイ料金 ：基本無料(一部アイテム課金あり)</p>
      <p>ゲームジャンル：都市風対戦型オンライン麻雀</p>
      <p>公式Twitter：<a class="ytLink" href="https://twitter.com/RiichiCity_JP">https://twitter.com/RiichiCity_JP</a></p>
      <p>公式YouTubeチャンネル：<a class="ytLink" href="https://www.youtube.com/channel/UC3fBAXW5VNK4JLvOfoYHTAQ">https://www.youtube.com/channel/UC3fBAXW5VNK4JLvOfoYHTAQ</a></p>
      <p>予約トップ10事前登録：<a class="ytLink" href="https://yoyaku-top10.jp/u/a/MzIxNTk">https://yoyaku-top10.jp/u/a/MzIxNTk</a></p>
      <p class="link">Google Play事前登録：<a class="ytLink" href="https://play.google.com/store/apps/details?id=com.riichicity.happywoods">https://play.google.com/store/apps/details?id=com.riichicity.happywoods</a></p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.content{
  margin: 10px 20px 0 20px; 
  text-align: left;
  p{
    color: #ffffff;
    text-indent: 1em; 
    font-weight: 500;
    &.titleName{
      // font-family: 'Source Han Sans-bold';
      font-size: 32px;
      text-indent: 0em;
    }
    &.subtitleName{
      font-size: 26px;
      text-indent: 0em;
    }
    &.link{
      word-break: keep-all;
    }
  }
  img{
    width: 100%;
    max-width: 300px;
    height: auto;
  }
}
@media only screen and (max-width: 767px) {
    a{
    &.ytLink{
      display: inline-block;
      word-break: keep-all;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
