<template>
  <div class="content">
    <div>
      <p v-if="showParagraph('news.p28')">{{ $t("news.p28") }}</p>
      <p v-if="showParagraph('news.p29')">{{ $t("news.p29") }}</p>
      <p>{{ $t("news.p30") }}</p>
      <p>{{ $t("news.p31") }}</p>
    </div>
    <div>
      <img :src="imgSrc" alt="" />
    </div>

    <div>
      <p>{{ $t("news.p32") }}</p>
      <p>{{ $t("news.p33") }}</p>
      <p>{{ $t("news.p34") }}</p>
    </div>
    <ul>
      <li>{{ $t("news.p35") }}</li>
      <li>{{ $t("news.p36") }}</li>
      <li>{{ $t("news.p37") }}</li>
      <li>{{ $t("news.p38") }}</li>
      <li>{{ $t("news.p39") }}</li>
      <li>{{ $t("news.p40") }}</li>
    </ul>
    <div>
      <p v-if="showParagraph('news.p41')">{{ $t("news.p41") }}</p>
      <p v-if="showParagraph('news.p42')">{{ $t("news.p42") }}</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed:{
    imgSrc(){
      return `/static/img/news/page10/header_${this.$store.state.language}.jpg`
    }
  },
  methods: {
    showParagraph(paragraph) {
      // console.log(paragraph);
      // console.log(this.$t(paragraph));
      return this.$t(paragraph) !== paragraph;
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  margin: 30px 20px;
  text-align: left;
  p {
    margin: 20px 0;
    color: #ffffff;
    font-weight: 500;
    white-space: pre-line;
  }
  ul {
    list-style: none;
    li {
      color: #ffffff;
      font-weight: 500;
      white-space: pre-line;
    }
  }
  img {
    width: 100%;
    // max-width: 768px;
    height: auto;
  }
}
</style>
