<template>
  <div class="content">
    <div>
      <p>オンライン麻雀ゲーム『麻雀一番街』（英語名：Riichi City）の事前登録は本日より開始いたします。リリース時期は今冬の予定。</p>
      <p>今後の『麻雀一番街（Riichi City）』にご期待ください！</p>
    </div>
    <div>
      <p class="titleName">■事前登録キャンペーン開催！</p>
      <p>本日11月30日より、事前登録をスタートいたしました！エントリー詳細につきましては、事前登録キャンペーン詳細ページをご覧ください。</p>
      <p>事前登録者数に応じて、全員に豪華アイテムをプレゼントします。</p>
      <p>ぜひ事前登録して頂き、サービス開始をお待ち下さいませ！</p>
      <img src="/static/img/news/page01Image.jpg">
    </div>
    <div>
      <p class="titleName">■ゲーム概要</p>
      <p>タイトル名 ：麻雀一番街</p>
      <p>配信予定日 ：2021年冬</p> 
      <p>プレイ料金 ：基本無料(一部アイテム課金あり)</p>
      <p>ゲームジャンル：都市風対戦型オンライン麻雀</p>
      <p>公式Twitter：<a href="https://twitter.com/RiichiCity_JP">https://twitter.com/RiichiCity_JP</a></p>
      <p>公式YouTubeチャンネル：<a class="ytLink" href="https://www.youtube.com/channel/UC3fBAXW5VNK4JLvOfoYHTAQ">https://www.youtube.com/channel/UC3fBAXW5VNK4JLvOfoYHTAQ</a></p>
      <p>事前登録サイト：<a href="http://www.mahjong-jp.com/preRegister">http://www.mahjong-jp.com/preRegister</a></p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.content{
  margin: 10px 20px 0 20px; 
  text-align: left;
  p{
    color: #ffffff;
    text-indent: 1em; 
    font-weight: 500;
    &.titleName{
      // font-family: 'Source Han Sans-bold';
      font-weight: 500;
      font-size: 32px;
      text-indent: 0em;
    }
    &.subtitleName{
      font-size: 26px;
      text-indent: 0em;
    }
  }
  img{
    width: 100%;
    max-width: 768px;
    height: auto;
  }
}
@media only screen and (max-width: 767px) {
    a{
    &.ytLink{
      display: inline-block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>